import React from 'react';
import { useAppSelector } from '../../../store/hooks';

export default () => {
  const currentLevel = useAppSelector((state) => state.account.level);
  const experience = useAppSelector((state) => state.account.experience);
  const experienceToNextLevel = useAppSelector((state) => state.account.expToNextLevel);

  if (currentLevel === null || experienceToNextLevel === null || experience === null) {
    return null;
  }

  return (
    <div className="flex flex-row w-max">
      <div className="flex flex-row">
        <div className="pl-4 md:pl-6 bg-mainBg bg-opacity-50 text-white uppercase font-title text-xs">
          lvl.
        </div>
        <div className="bg-mainBg bg-opacity-50 text-white uppercase font-bold text-lg">
          <div className="drop-shadow-inheritColorExtraSmall">
            {currentLevel.toString()}
          </div>
        </div>
      </div>
      <div className="px-4 flex flex-col bg-mainBg bg-opacity-50">
        <div className="flex flex-row justify-between">
          <div className="text-white uppercase font-title text-xs">
            Exp. to next
          </div>
          <div className="text-white uppercase font-title text-xs">
            {Math.round(experience)}/{Math.round(experienceToNextLevel)}
          </div>
        </div>
        <div
          className="relative flex w-44 h-1 bg-white/10"
          style={{
            maskImage: 'linear-gradient(90deg, transparent 0, rgba(0, 0, 0, 1) 10px, rgba(0, 0, 0, 1) calc(100% - 10px), transparent 100%)',
          }}
        >
          <div
            className="absolute left-0 h-1 bg-green-600"
            style={{
              width: `${experience / experienceToNextLevel * 100}%`,
            }}
          />
        </div>
      </div>
      <div
        className="w-8 bg-mainBg bg-opacity-50"
        style={{
          clipPath: 'polygon(0 0, 0 100%, 100% 0)',
        }}
      />
    </div>
  );
};
