import React from 'react';
import { createExtraReducer, ExtraReducers } from '../../parseExtraReducersCallbacks';
import { InventoryState } from './inventorySlice';
import { fetchGemhuntersApi } from '../../../utils/fetcher';
import {
  ExtraReducerGenericArg,
  GetOwnedItemsAPIResponse,
  InventoryItem,
} from '../../../utils/additionalTypes';
import fetcherReducerErrorHandler from '../../../utils/fetcherReducerErrorHandler';
import modalService from '../../../modalService/ModalService';
import ErrorModal from '../../../components/modals/ErrorModal';
import endpointUrl from '../../../utils/endpointUrl';
import {
  BERA_ATTRIBUTES, DUST_TYPE, GEAR_RARITY,
} from '../../../utils/consts';
import LoaderModal from '../../../components/modals/LoaderModal';
import NewItemEquippedSuccessModal from '../../../components/modals/NewItemEquippedSuccessModal';
import { berasExtraReducers } from '../beras/berasReducers';
import externalItemData from '../../../utils/externalItemData';
import DisenchantingResultsModal from '../../../components/modals/DisenchantingResultsModal';

// eslint-disable-next-line import/prefer-default-export
export const inventoryExtraReducers: ExtraReducers = {
  getOwnedItems: createExtraReducer<InventoryState, Array<InventoryItem>, ExtraReducerGenericArg>({
    reducer: {
      name: 'inventory/getOwnedItems',
      callbackFn: async (extraReducerGenericArg, { dispatch, rejectWithValue }) => {
        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/inventory`, {
            method: 'GET',
          }) as GetOwnedItemsAPIResponse;

          const { Inventory } = res;

          if (Inventory) { // TODO better validation
            return Inventory.map((item) => {
              if (item.ItemImage.kind === 'link' && item.ItemImage.value.includes('seadn')) {
                item.ItemImage.value = `${item.ItemImage.value}?auto=format&dpr=1&w=200`;
              }

              // lookup gs & ID in external definition
              if (item.ItemGearType) {
                // eslint-disable-next-line no-shadow,no-unused-vars
                const [externalId, externalAttributes] = Object.entries(externalItemData).find(([_, { Name }]) => Name === item.ItemName) ?? [];

                if (externalId && externalAttributes) {
                  item.gearScore = GEAR_RARITY[externalAttributes.Rarity as keyof typeof GEAR_RARITY];
                  item.externalId = externalId;
                }
              }

              return item;
            });
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          if (extraReducerGenericArg?.failImplicit) {
            return rejectWithValue(null);
          }

          return rejectWithValue(error);
        }
      },
    },
    fulfilledCallback: (state, { payload }) => {
      state.ownedItems = payload;
    },
    rejectedCallback: (_, { payload }) => {
      if (payload) {
        modalService.pushModal(<ErrorModal error={payload} />);
      }
    },
  }),
  equipItems: createExtraReducer<null, string, {
      beraId: string,
      itemsToEquip: Array<{
        ItemID: string
      }>,
      signature: string
    }>({
      reducer: {
        name: 'inventory/equipItems',
        callbackFn: async ({
          beraId,
          itemsToEquip,
          signature,
        }, { dispatch, rejectWithValue }) => {
          try {
            const res = await fetchGemhuntersApi(`${endpointUrl}/api/armory/equip`, {
              method: 'POST',
              body: JSON.stringify({
                tokenId: beraId,
                items: itemsToEquip,
                signature,
              }),
            }) as {
            message: string
            updateStatus: string
            url: string
          };

            if (res.message === 'Success') {
              dispatch(inventoryExtraReducers.getOwnedItems.reducer(null));
              dispatch(berasExtraReducers.getBeras.reducer(null));

              return beraId;
            }

            return rejectWithValue(new Error('Missing data in response!!!'));
          } catch (error) {
            fetcherReducerErrorHandler({
              error,
              dispatch,
            });

            return rejectWithValue(error);
          }
        },
      },
      pendingCallback: () => {
        modalService.pushModal(<LoaderModal />, {
          canClose: false,
        });
      },
      fulfilledCallback: (_, { payload }) => {
        modalService.pushModal(<NewItemEquippedSuccessModal beraId={payload} />);
      },
      rejectedCallback: (_, { payload }) => {
        modalService.pushModal(<ErrorModal error={payload} />);
      },
    }),
  disenchantItems: createExtraReducer<null, Record<string, number>, {
    itemsToDisenchant: Array<{ ItemID: string }>,
    signature: string
  }>({
    reducer: {
      name: 'inventory/disenchantItems',
      callbackFn: async ({
        itemsToDisenchant,
        signature,
      }, { dispatch, rejectWithValue }) => {
        try {
          const res = await fetchGemhuntersApi(`${endpointUrl}/api/crafting/disenchant`, {
            method: 'POST',
            body: JSON.stringify({
              items: itemsToDisenchant,
              signature,
            }),
          }) as {
            message: string,
            // eslint-disable-next-line no-unused-vars
            items: { [P in typeof DUST_TYPE['Green Dust']
             | typeof DUST_TYPE['Blue Dust']
             | typeof DUST_TYPE['Purple Dust']
             | typeof DUST_TYPE['Orange Dust'] ]: number }
          };

          if (res.message === 'Success') {
            dispatch(inventoryExtraReducers.getOwnedItems.reducer(null));

            return res.items;
          }

          return rejectWithValue(new Error('Missing data in response!!!'));
        } catch (error) {
          fetcherReducerErrorHandler({
            error,
            dispatch,
          });

          return rejectWithValue(error);
        }
      },
    },
    pendingCallback: () => {
      modalService.pushModal(<LoaderModal />, {
        canClose: false,
      });
    },
    fulfilledCallback: (_, { payload }) => {
      modalService.pushModal(<DisenchantingResultsModal acquiredMaterials={payload} />);
    },
    rejectedCallback: (_, { payload }) => {
      modalService.pushModal(<ErrorModal error={payload} />);
    },
  }),
};
